import axios from "axios"
import moment from "moment"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Hammer from "../../images/justice-hammer.svg"
import Button from "../shared/button"
import InputMask from "react-input-mask"

class BiddingContent extends Component {
  initialState = {
    phone: "",
    rate: "",
    valid: false,
    submitted: false,
    bid: {},
  }
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  componentDidMount() {
    const { token } = this.props
    const validateToken = async () => {
      try {
        await axios({
          method: "post",
          url: `${process.env.apiurl}global/VerifyToken/`,
          headers: {
            "bid-auth-token": `${token}`,
          },
        }).then(res => {
          this.setState({ bid: res.data, valid: true })
        })
      } catch (error) {
        console.log(error)
        this.setState({ valid: false })
      }
    }
    validateToken()
  }

  handlePhoneChange = e => {
    this.setState({ phone: e.target.value })
  }

  handleRateChange = e => {
    this.setState({ rate: e.target.value })
  }

  handleSubmit = e => {
    const { bid, phone, rate } = this.state
    e.preventDefault()
    bid.CarrierPhone = phone
    bid.Rate = rate
    const insertBid = async () => {
      try {
        await axios
          .post(`${process.env.apiurl}global/InsertBid`, bid)
          .then(res => this.setState({ submitted: true }))
      } catch (error) {
        console.log(error)
        this.setState({ submitted: false })
      }
    }
    insertBid()
    this.setState({ phone: "", rate: "" })
    setTimeout(() => {
      this.setState({ submitted: false })
    }, 5000)
  }

  render() {
    const { bid, phone, rate, valid, submitted } = this.state
    return (
      <div className="container mx-auto p-6 mt-16 lg:mt-32 lg:mb-12">
        {valid ? (
          <div className="max-w-full rounded overflow-hidden shadow-lg">
            <div className="px-6 py-4 bg-gray-100">
              <h1 className="text-2xl font-semibold mb-2 text-gray-700 flex items-center">
                <img src={Hammer} alt="Bid" height="42" width="42" />
                &nbsp; Current Bid
                {bid.LoadNumber ? (
                  <span className="pl-2 text-lg text-gray-600">
                    / {bid.LoadNumber}
                  </span>
                ) : null}
              </h1>
            </div>
            <div className="lg:flex lg:flex-wrap">
              <div className="lg:w-full">
                <div className="px-6 py-4">
                  <h3 className="text-2xl text-gray-700 font-semibold">
                    Place Your Bid
                  </h3>
                  <form
                    onSubmit={this.handleSubmit}
                    className="pt-5 lg:flex lg:flex-wrap"
                  >
                    <div className="lg:w-full lg:flex">
                      <div className="lg:w-1/2 lg:mr-5">
                        <label
                          htmlFor="carrierPhone"
                          className="block text-gray-700 text-md font-bold mb-1"
                        >
                          Phone Number
                        </label>
                        <InputMask
                            mask="(999) 999-9999"
                            type="tel"
                            id="carrierPhone"
                            title="Bidder Phone Number"
                            placeholder="(123) 456-7890"
                            className="appearance-none bg-gray-200 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
                            value={phone}
                            onChange={this.handlePhoneChange}
                            name="phone"
                            required
                          ></InputMask>
                      </div>
                      <div className="lg:w-1/2 pt-4 lg:pt-0">
                        <label
                          htmlFor="carrierRate"
                          className="block text-gray-700 text-md font-bold mb-1"
                        >
                          Rate
                        </label>
                        <input
                          className="appearance-none bg-gray-200 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
                          id="carrierRate"
                          type="number"
                          value={rate}
                          name="rate"
                          onChange={this.handleRateChange}
                          title="Bidder Rate"
                          placeholder="12345"
                          required
                        />
                      </div>
                    </div>
                    <div className="lg:w-full text-right pt-5">
                      <Button
                        className="bg-black hover:bg-gray-800 active:bg-gray-900 hover:text-gray-100 focus:outline-none text-gray-100 font-semibold lg:text-lg py-2 px-6 rounded"
                        type="submit"
                      >
                        Place Bid
                      </Button>
                    </div>
                  </form>
                  {submitted ? (
                    <div
                      className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mt-4 shadow-md"
                      role="alert"
                    >
                      <p className="font-bold">Success!</p>
                      <p>You have placed a bid on this load.</p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="lg:w-full bg-gray-100">
                <div className="px-6 py-3">
                  <h3 className="text-2xl text-gray-700 font-semibold">
                    Load Details
                  </h3>
                </div>
                <div className="px-6 py-4 flex flex-wrap">
                  <div className="lg:w-1/2 lg:pr-5">
                    {bid.LoadOrigin ? (
                      <div className="block rounded bg-white shadow-md mb-4 px-3 py-2 border-l-4 border-gray-500">
                        <h4 className="text-md font-bold text-gray-700">
                          Origin
                        </h4>
                        <div className="text-gray-600">{bid.LoadOrigin}</div>
                      </div>
                    ) : null}
                    {bid.PickupDate ? (
                      <div className="block rounded bg-white shadow-md mb-4 px-3 py-2 border-l-4 border-gray-500">
                        <h4 className="text-md font-bold text-gray-700">
                          Pickup Date
                        </h4>
                        <div className="text-gray-600">
                          {moment(bid.PickupDate).format("MMMM Do YYYY")}
                        </div>
                      </div>
                    ) : null}
                    {bid.Miles ? (
                      <div className="block rounded bg-white shadow-md mb-4 px-3 py-2 border-l-4 border-gray-500">
                        <h4 className="text-md font-bold text-gray-700">
                          Total Miles
                        </h4>
                        <div className="text-gray-600">
                          {bid.Miles.toLocaleString()}
                        </div>
                      </div>
                    ) : null}
                    {bid.EquipmentType ? (
                      <div className="block rounded bg-white shadow-md mb-4 px-3 py-2 border-l-4 border-gray-500">
                        <h4 className="text-md font-bold text-gray-700">
                          Equipment Type
                        </h4>
                        <div className="text-gray-600">{bid.EquipmentType}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="lg:w-1/2">
                    {bid.LoadDestination ? (
                      <div className="block rounded bg-white shadow-md mb-4 px-3 py-2 border-l-4 border-gray-500">
                        <h4 className="text-md font-bold text-gray-700">
                          Destination
                        </h4>
                        <div className="text-gray-600">
                          {bid.LoadDestination}
                        </div>
                      </div>
                    ) : null}
                    {bid.DeliveryDate ? (
                      <div className="block rounded bg-white shadow-md mb-4 px-3 py-2 border-l-4 border-gray-500">
                        <h4 className="text-md font-bold text-gray-700">
                          Delivery Date
                        </h4>
                        <div className="text-gray-600">
                          {moment(bid.DeliveryDate).format("MMMM Do YYYY")}
                        </div>
                      </div>
                    ) : null}
                    {bid.Weight ? (
                      <div className="block rounded bg-white shadow-md mb-4 px-3 py-2 border-l-4 border-gray-500">
                        <h4 className="text-md font-bold text-gray-700">
                          Weight
                        </h4>
                        <div className="text-gray-600">
                          {bid.Weight.toLocaleString()} lbs
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="lg:w-full">
                <div className="px-6 py-3">
                  <h3 className="text-2xl text-gray-700 font-semibold">
                    Stop Details
                  </h3>
                </div>
                <div className="px-6 py-4 flex flex-wrap">
                  {bid.Stops.length > 0
                    ? bid.Stops.map(stop => (
                        <div
                          className={`rounded bg-gray-100 shadow-md mb-4 px-3 py-2 border-l-4 mr-5 ${
                            stop.Type === "Pickup"
                              ? "border-red-300"
                              : "border-blue-300"
                          }`}
                        >
                          {stop.Type ? (
                            <h4 className="text-md font-bold text-gray-700">
                              {stop.Type}
                            </h4>
                          ) : null}
                          {stop.Address ? (
                            <div className="text-gray-600">{stop.Address}</div>
                          ) : null}
                          {stop.Date ? (
                            <div className="pt-2">
                              <h4 className="text-md font-semibold text-gray-800">
                                Expected
                              </h4>
                              <div className="text-gray-600">
                                {moment(stop.Date).format("MMMM Do YYYY")}
                                {stop.Appointment ? (
                                  <span className="text-gray-600">
                                    {" "}
                                    @ {stop.Appointment}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-gray-700">
            <h2 className="text-3xl font-semibold">Page Failed to Load</h2>
            <p className="text-xl my-8 font-medium">
              Sorry, we couldn&#39;t find that bid... The link you clicked may
              be expired or invalid.
            </p>
            <div className="text-sm mt-4 font-semibold text-gray-700">
              Having any issues? <br /> Call us at{" "}
              <a className="text-blue-500" href="tel:303-542-7110">
                (303) 542 - 7110
              </a>{" "}
              or email{" "}
              <a
                className="text-blue-500"
                href="mailto:info@archerhub.com?Subject=Enquiry"
                target="_top"
              >
                support@archerhub.com
              </a>
            </div>
          </div>
        )}
      </div>
    )
  }
}

BiddingContent.propTypes = {
  token: PropTypes.string.isRequired,
}

BiddingContent.defaultProps = {
  token: null,
}

export default BiddingContent
