import React, { Component } from "react"
import queryString from "query-string"
import Layout from "../components/layout"
import BiddingContent from "../components/bidding/bidding-content"

class Bidding extends Component {
  token = ""
  constructor(props) {
    super(props)
    let jwt = this.props.location.search
      ? queryString.parse(this.props.location.search)
      : {}
    if (jwt.data) {
      this.token = jwt.data
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let jwt = this.props.location.search
        ? queryString.parse(this.props.location.search)
        : {}
      if (jwt.data) {
        this.token = jwt.data
      }
    }
  }
  render() {
    return (
      <Layout>
        <BiddingContent token={this.token} />
      </Layout>
    )
  }
}

export default Bidding
